<template>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-buttons slot="start">
        <ion-back-button text=""/>
      </ion-buttons>
      <ion-title class="ion-text-capitalize">{{ title }}</ion-title>
    </ion-toolbar>
  </ion-header>
</template>

<script lang="ts">
import { IonHeader, IonToolbar, IonTitle, IonBackButton, IonButtons } from '@ionic/vue';
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageHeader",
  props: ['title'],
  components: {IonHeader, IonToolbar, IonTitle, IonBackButton, IonButtons}
})
</script>
