import { cardOutline, logoApple, walletOutline } from 'ionicons/icons';

type OptionsType = {
  [key: string]: string;
}

type PaymentButtonOptionsType = {
  [key: string]: { name: string; buttonTitle: string; buttonIcon?: string; icon: string; color?: string };
}

export const orderStatuses: OptionsType = {
  "pending": "Pendiente de pago",
  "processing": "En proceso",
  "on-hold": "En proceso",
  "completed": "Completado",
  "cancelled": "Cancelado",
  "refunded": "Reembolsado",
  "failed": "Fallido",
}

export const errorMessages: OptionsType = {
  "auth.unexpected_error": "Ha ocurrido un error inexperado.",
  "auth.invalid_grant": "Los datos introducidos son incorrectos.",
}

export const paymentMethods: PaymentButtonOptionsType = {
  "cod": {
    name: "En efectivo",
    icon: walletOutline,
    buttonTitle: "Realizar el pedido",
    buttonIcon: undefined,
    color: "primary"
  },
  "cc": {
    name: "Con tarjeta",
    icon: cardOutline,
    buttonTitle: "Pago seguro con tarjeta",
    buttonIcon: cardOutline,
    color: "primary"
  },
  "applepay": {
    name: "Apple Pay",
    icon: logoApple,
    buttonTitle: "Pagar con Apple Pay",
    buttonIcon: logoApple,
    color: "black"
  },
}

export const orderShippingMethods: OptionsType = {
  "free_shipping": "A domicilio",
  "local_pickup": "Recogida en el local",
}
