
import {
  IonPage,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonItem,
  IonBadge,
  IonSkeletonText,
  IonLabel, modalController
} from '@ionic/vue';
import PageHeader from '@/components/PageStructure/PageHeader.vue';
import OrderModal from "@/components/Modals/OrderModal.vue";
import { OrderType } from "@/interfaces/types";
import { orderStatuses } from "@/config/StaticStrings";
import { defineComponent, ref } from "vue";
import { getStoreOrders } from "@/services/Api";
import authModule from '@/store/modules/auth';

export default defineComponent({
  name: 'CustomerOrders',
  components: {
    PageHeader,
    IonPage,
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonList,
    IonItem,
    IonBadge,
    IonSkeletonText,
    IonLabel
  },
  methods: {
    async openOrderModal(order: any) {
      const thisPageRef: any = this.$refs.thisPage;
      const presentingElement: HTMLElement = thisPageRef.$el;

      const modal = await modalController
        .create({
          component: OrderModal,
          swipeToClose: true,
          presentingElement: presentingElement,
          componentProps: {
            order,
          },
        })
      return modal.present();
    },
  },
  setup() {
    let page = 1;
    let totalPages = 1;

    const customerId = authModule.loggedUserId;
    const isDisabled = ref(false);
    const orders = ref([] as OrderType[]);

    function processPostData(response: { orders: OrderType[]; totalPages: number }) {
      totalPages = +response.totalPages; // unary (+) operator casts the string to a number
      orders.value.push(...response.orders);
      isDisabled.value = page === totalPages;
    }

    const getOrders = (customerId: number, page: number, ev?: any) => {
      getStoreOrders(customerId, page).then((storeOrdersResponse) => {
        processPostData(storeOrdersResponse);
        if (ev) {
          ev.target.complete();
        }
      });
    }

    const loadData = (ev: any) => {
      page++;
      getOrders(customerId, page, ev);
    }

    getOrders(customerId, page);

    return {
      orders,
      isDisabled,
      loadData,
      orderStatuses,
    }
  },
})
