<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button @click="hideLoginModal">Cerrar</ion-button>
      </ion-buttons>
      <ion-title>Pedido #{{ order.id }}</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <ion-list lines="full">
      <ion-list-header>
        <ion-label>Datos del pedido</ion-label>
      </ion-list-header>
      <ion-item>
        <ion-label>Estado del pedido</ion-label>
        <ion-note slot="end">{{ orderStatuses[order.status] }}</ion-note>
      </ion-item>
      <ion-item>
        <ion-label>Fecha</ion-label>
        <ion-note slot="end">{{ $day(order.date_created).format("DD/MM/YYYY - HH:MM[h]").toLowerCase() }}</ion-note>
      </ion-item>
      <ion-item>
        <ion-label>Forma de pago</ion-label>
        <ion-note slot="end">{{ paymentMethods[order.payment_method].name }}</ion-note>
      </ion-item>
      <ion-item v-if="order.customer_note">
        <ion-label>Notas</ion-label>
        <ion-note slot="end">{{ order.customer_note }}</ion-note>
      </ion-item>
    </ion-list>
    <ion-list lines="full" v-if="order.shipping_lines[0].method_id !== 'local_pickup'">
      <ion-list-header>
        <ion-label>Datos de entrega</ion-label>
      </ion-list-header>
      <ion-item>
        <p>
          {{ order.shipping.first_name }} {{ order.shipping.last_name }}<br>
          {{ order.shipping.address_1 }} {{ order.shipping.address_2 }}<br>
          {{ order.shipping.city }} ({{ order.shipping.state }}) {{ order.shipping.postcode }}<br>
        </p>
      </ion-item>
    </ion-list>
    <ion-list lines="full">
      <ion-list-header>
        <ion-label>Contenido del pedido</ion-label>
      </ion-list-header>
      <ion-item v-for="(item, index) in order.line_items" :key="index">
        <ion-badge slot="start" color="light">{{ item.quantity }}</ion-badge>
        <ion-label class='ion-text-wrap'>
          <h2>{{ item.name }}</h2>
          <template v-for="(metadata, index) in uniqueMetadata(item.meta_data)">
            <ion-text :key="index" v-if="!isDefaultOption(metadata)" class="variants"><strong
              class="ion-text-capitalize">{{ metadata.key }}:</strong> {{ capitalizeList(metadata.value) }}
            </ion-text>
          </template>
        </ion-label>
        <ion-text slot="end" class='ion-text-wrap' color="dark">{{ $currency(item.price) }}</ion-text>
      </ion-item>
    </ion-list>
  </ion-content>

  <ion-footer>
    <ion-toolbar color="light">
      <ion-row class="ion-margin">
        <ion-col class="ion-no-padding">
          <ion-label class="ion-no-margin"><small>Subtotal</small></ion-label>
        </ion-col>
        <ion-col class="ion-no-padding">
          <ion-label class="ion-float-end cart-total">
            <small>{{ $currency(order.total) }}</small>
          </ion-label>
        </ion-col>
      </ion-row>
      <ion-row class="ion-margin">
        <ion-col class="ion-no-padding">
          <ion-label class="ion-no-margin"><small>Envío <span style="font-style: italic">({{
              orderShippingMethods[order.shipping_lines[0].method_id].toLowerCase()
            }})</span></small></ion-label>
        </ion-col>
        <ion-col class="ion-no-padding">
          <ion-label class="ion-float-end cart-total">
            <small>{{ $currency(order.shipping_total) }}</small>
          </ion-label>
        </ion-col>
      </ion-row>
      <ion-row class="ion-margin" v-if="order.coupon_lines.length">
        <ion-col class="ion-no-padding">
          <ion-label class="ion-no-margin"><small>Descuentos <span
            style="font-style: italic">({{ order.coupon_lines[0].code }})</span></small></ion-label>
        </ion-col>
        <ion-col class="ion-no-padding">
          <ion-label class="ion-float-end cart-total">
            <small>{{ $currency(order.discount_total) }}</small>
          </ion-label>
        </ion-col>
      </ion-row>
      <ion-row class="ion-margin">
        <ion-col class="ion-no-padding">
          <ion-label class="ion-no-margin"><strong>Total</strong> <small style="color: #8c8c8c">IVA Incluído</small>
          </ion-label>
        </ion-col>
        <ion-col class="ion-no-padding">
          <ion-label class="ion-float-end cart-total">
            <strong>{{ $currency(order.total) }}</strong>
          </ion-label>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import {
  IonFooter,
  IonListHeader,
  IonContent,
  IonBadge,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonButton,
  modalController
} from '@ionic/vue';
import { orderStatuses, paymentMethods, orderShippingMethods } from "@/config/StaticStrings";
import { defineComponent } from "vue";

export default defineComponent({
  name: 'OrderModal',
  components: {
    IonFooter,
    IonListHeader,
    IonContent,
    IonBadge,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonLabel,
    IonText,
    IonButton
  },
  props: ['order'],
  methods: {
    hideLoginModal: function () {
      modalController.dismiss()
    },
    isDefaultOption(metadata) {
      return metadata.key === 'Tipo de carne' && metadata.value.length === 1 && metadata.value[0] === 'Carne de ternera' ||
        metadata.key === 'Salsa' && metadata.value.length === 1 && metadata.value[0] === 'Con mayonesa';
    },
    capitalizeList(list) {
      const joinedList = list.join(', ').toLowerCase();
      return joinedList.charAt(0).toUpperCase() + joinedList.slice(1);
    },
    uniqueMetadata(metadata) {
      const output = [];
      metadata.forEach((item) => {
        if (!item.key.startsWith('_')) {
          const existing = output.filter((v) => v.key === item.key);
          if (existing.length) {
            const existingIndex = output.indexOf(existing[0]);
            if (!output[existingIndex].value.includes(item.value)) {
              output[existingIndex].value = output[existingIndex].value.concat(item.value);
            }
          } else {
            if (typeof item.value === 'string') item.value = [item.value];
            output.push(item);
          }
        }
      });
      return output;
    },
  },
  setup() {
    return {
      paymentMethods,
      orderStatuses,
      orderShippingMethods,
    };
  }
})
</script>

<style lang="scss" scoped>
.variants {
  font-size: 75%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
